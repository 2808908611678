.scroll-container {
  overflow: hidden;
  > .scroll {
    width: 100%;
    padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
    box-sizing: content-box; /* So the width will be 100% + 17px */
    overflow-x: hidden;
    overflow-y: scroll;
  }
}
