@import "./fonts.scss";

$hover-height: 8px;
$yellow: rgb(255, 223, 42);

@-ms-viewport {
  width: device-width;
}
a {
  text-decoration: none;
  color:#ffead6;

  &:visited {
    color:#ffead6;
  }
}
body {
  // background: #656a73;
  background: rgb(8, 5, 2);
  // background: rgb(14, 10, 8);
  // height: 100%;
  // width: 100%;
  // color: rgb(255, 232, 204);
  // color: #000a05;
  // color: #280e17;
  // color: #fff8eb;
  color: #ffead6;
  // position: absolute;
  display: block;
  margin: 0;
  // height: 100%;
  justify-content: center;
  align-items: center;
}

div {
  font-family: $bodyText;
}

h1 {
  font-family: $headerText;
  // text-transform: uppercase ;
  font-size: 80px;
  margin: 0;
  font-weight: normal;
}

h2 {
  margin: 1px 0;
  font-size: 34px;
}

.underline-hover {
  cursor: pointer;
  &:hover,
  &:active {
    text-decoration: underline;
  }
}
.container {
  display: flex;

  .debug-outline {
    border-color: khaki;
  }
}

$hover-height: 8px;
.hover-text {
  cursor: pointer;

  span,
  a {
    text-shadow: 0px 0px 1px rgba(0, 0, 0, 0);
    box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0.5);

    transition: box-shadow 80ms ease-in-out, color 80ms ease-in-out, 80ms ease-in-out, text-shadow 80ms ease-in-out;
    user-select: none;
  }

  &:hover,
  &.hovered {
    span,
    a {
      text-shadow: 7px $hover-height 2px rgba(0, 0, 0, 0.6);
      transform: translate(0px, -$hover-height);
    }
  }

  &:active,
  &.active {
    span,
    a {
      color: rgb(62, 255, 191);
      text-shadow: 3px ($hover-height - 2px) 2px rgba(0, 0, 0, 0.2), 3px ($hover-height - 2px) 1px rgb(255, 62, 255);
      box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
      transform: translate(0px, -$hover-height + 2px);
    }
  }
}
.fullscreen {
  height: 100vh;
  width: 100vw;
}

p {
  margin: 10; 
}

.fill-container {
  height: 100%;
  width: 100%;
  display: flex;
}

.fill-width {
  width: 100%;
  display: flex;
}

.fill-height {
  height: 100%;
  display: flex;
}

div {
  display: flex;
}
.centered {
  justify-content: center;
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.one-third-width {
  width: calc(100% / 3);
  min-width: 600px;
}

.no-text-select {
  user-select: none;
}

.slight-pad {
  padding: 4px;
}

@import "./media-queries.scss";
