@font-face {
  font-family: "Lifted";
  src: url("https://robertvinluan.com/assets/webfonts/Lifted-Sans.eot"); /* IE9 Compat Modes */
  src: url("https://robertvinluan.com/assets/webfonts/Lifted-Sans.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("https://robertvinluan.com/assets/webfonts/Lifted-Sans.woff2") format("woff2"),
    /* Super Modern Browsers */ url("https://robertvinluan.com/assets/webfonts/Lifted-Sans.woff") format("woff");
}

@font-face {
  font-family: "mplus m1 light";
  src: url("./fonts/mplus-1m-light.ttf") format("truetype");
}

@font-face {
  font-family: "Archivo Black Regular";
  src: url("./fonts/ArchivoBlack-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Cotham Sans";
  src: url("./fonts/CothamSans.otf") format("opentype");
}
@font-face {
  font-family: "Libre Baskerville";
  font-weight: 400;
  src: url("./fonts/Libre-Baskerville/LibreBaskerville-Regular.ttf") format("woff");
}

@font-face {
  font-family: "OfficeCodePro";
  src: url("./fonts/Office\ Code\ Pro/WOFF/OfficeCodePro-Light.woff") format("woff");
}

@font-face {
  font-family: "CooperHewittHeavy";
  src: url("./fonts/CooperHewitt/CooperHewitt-Heavy.otf") format("opentype");
}

@font-face {
  font-family: "Bluu Next";
  src: url("./fonts/BluuNext-Bold.otf") format("opentype");
}
@font-face {
  font-family: "YoungSerif";
  src: url("./fonts/YoungSerif-Regular.otf") format("opentype");
}
@font-face {
  font-family: "Nichrome Black";
  src: url("./fonts/Nichrome0.1-Black.otf") format("opentype");
}
@font-face {
  font-family: "Nichrome Regular";
  src: url("./fonts/Nichrome0.1-Regular.otf") format("opentype");
}
@font-face {
  font-family: "League Gothic Condensed Italic";
  src: url("./fonts/LeagueGothic-CondensedItalic\ \(1\).otf") format("opentype");
}
@font-face {
  font-family: "League Gothic Italic";
  src: url("./fonts/LeagueGothic-Italic.otf") format("opentype");
}

$displayText: "YoungSerif";
$subHeaderText: "mplus m1 light";
$bodyText: "Libre Baskerville";
$headerText: "YoungSerif";

.title-text {
  font-family: $displayText;
}
code {
  font-family: $subHeaderText;
}

.info-title-text {
  font-family: $displayText;
  font-size: 20px;
}

.display-text {
  font-family: $displayText;
  color: #ffb825;
}

.sub-header-text {
  font-family: $subHeaderText;
  font-size: 16px;
  line-height: 20px;
}

.info-body-text {
  font-family: $bodyText;
  text-align: left;
  font-size: 20px;
  line-height: 26px;
}

.body {
  font-family: $bodyText;
}